import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare const $: any;

export class ScrollToElement extends AbstractComponent
{
	public static selector: string = 'scroll-to-element';

	public init()
	{
		let element = this.getComponentElement();
		let scrollToElementTime = element.attr('scroll-to-element-time');
		scrollToElementTime = (scrollToElementTime === 'undefined')?2000:scrollToElementTime;
		element.on('click', () => {
			$([document.documentElement, document.body]).animate({
				scrollTop: $(this.getComponentParameter()).offset().top
			}, scrollToElementTime);
		});
	}

}

new ComponentLoader(ScrollToElement);
